<template>
  <div class="addSubscribe">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      @update="update"
    >
      <el-form-item
        label="url地址名称"
        prop="openUrlName"
        :rules="[{ required: true, message: 'url地址名称', trigger: 'change' }]"
      >
        <v-input
          :width="300"
          placeholder="请输入url地址名称"
          v-model="form.openUrlName"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="url地址"
        prop="openUrl"
        :rules="[{ required: true, message: 'url地址', trigger: 'change' }]"
      >
        <v-input
          :width="300"
          placeholder="请输入url地址"
          v-model="form.openUrl"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="每天调用次数上限"
        prop="limitPerDay"
        :rules="[
          { required: true, message: '每天调用次数上限', trigger: 'change' },
        ]"
      >
        <v-input
          :width="300"
          placeholder="请输入每天调用次数上限"
          v-model="form.limitPerDay"
        ></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { addSubscribeListUrl, subscribeDetailUrl } from "./api.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
import {} from "./map";

export default {
  name: "addSubscribe",
  data() {
    return {
      submitConfig: {
        queryUrl: subscribeDetailUrl,
        submitUrl: addSubscribeListUrl,
      },
      form: {
        openUrlName: "",
        openUrl: "",
        limitPerDay: "",
      },
    };
  },
  components: {},
  mounted() {
    const { id } = this.$route.query;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id: id });
    }

    this.$setBreadList(id ? "编辑" : "新增");
  },
  computed: {},
  methods: {
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.addSubscribe {
  box-sizing: border-box;
  height: 100%;
  /deep/ .el-form-item__label {
    width: 180px !important;
  }
}
</style>
